import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container } from "react-bootstrap"
import ContactForm from "../components/contactForm"
import ContactData from "../components/contactdata"
import ContactButtons from "../components/contactButtons"
import TopicList from "../components/topicList"
import CheckText from "../components/textwithcheck"
import FaQ from "../components/FaQ"
import FacebookLink from "../components/facebook"
import Aos from "aos"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Contact = ({ data }) => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  const {
    title,
    header,
    header2,
    introText,
    phone,
    email,
    whatsapp,
    skype,
    thumbnail,
    contactLink,
  } = data.sanityContacts

  const picture = getImage(thumbnail.asset)

  return (
    <Layout>
      <Seo
        title={title}
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="xl">
        <Row>
          {/* photo and text block */}
          <Col
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="py-5 arrowbackground-1"
          >
            <Container fluid="lg">
              <Row className="uk-heading-divider">
                <Col xl={4} lg={4} md={3} sm={2} xs={12} className="pb-3">
                  <figure>
                    <GatsbyImage
                      image={picture}
                      data-aos="fade-in"
                      className="rounded"
                      alt={thumbnail.attribution || "Степаненко Александр"}
                    />
                    {picture.capton !== null && (
                      <figcaption className="text-white pt-2">
                        {thumbnail.caption}
                      </figcaption>
                    )}
                  </figure>
                </Col>
                <Col xl={8} lg={8} md={9} sm={10} xs={12}>
                  <div className="text-white">
                    <h1
                      className="display-6 uk-heading-divider"
                      data-aos="fade-in"
                    >
                      {header}
                      <br />
                      <span style={{ fontWeight: "200" }}>{header2}</span>
                    </h1>
                    <p className="uk-text-meta">{introText}</p>
                  </div>
                  <ContactData phone={phone} />
                  <ContactButtons
                    email={email}
                    whatsapp={whatsapp}
                    skype={skype}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ContactForm contactLink={contactLink} />
                </Col>
              </Row>
            </Container>
          </Col>
          {/* /* basic topics */}
          <Col xl={4} lg={4} md={12} sm={12} xs={12} className="p-5 bg-primary">
            <TopicList />
            <FaQ />
            <FacebookLink />
          </Col>
        </Row>
      </Container>
      <CheckText text="опыт&nbsp;работы более 10&nbsp;лет" />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityContacts {
      title
      header
      header2
      introText
      phone
      email
      whatsapp
      skype
      contactLink
      thumbnail {
        asset {
          gatsbyImageData
        }
        attribution
        caption
      }
    }
  }
`

export default Contact
