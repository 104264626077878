import React from "react"
import { Row, Col, Form, Button, Alert } from "react-bootstrap"
import { Link } from "gatsby"
import { useFormik } from "formik"
import * as Yup from "yup"
import Axios from "axios"

const ContactForm = ({ contactLink }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      thema: "",
      message: "",
      terms: "",
      honeypot: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .strict(true)
        .trim("Не пишите пробелы")
        .matches(
          /^[a-zA-Za-яА-Я ]+$/,
          "Не используйте цифры и специальные знаки. Только заглавные и строчные буквы."
        )
        .min(3, "Не менее 3 символов")
        .max(20, "Не более 20 символов")
        .required("Обязательное поле"),
      email: Yup.string()
        .email("Неверный адрес электронной почты")
        .required("Обязательное поле"),
      thema: Yup.string()
        .strict(true)
        .trim("Не пишите лишние пробелы в начале и в конце сообщения")
        .min(2, "Не менее 2 символов")
        .max(20, "Не более 20 символов")
        .required("Обязательное поле"),
      message: Yup.string()
        .strict(true)
        .trim("Не пишите лишние пробелы в начале и в конце сообщения")
        .min(10, "Не менее 10 символов")
        .max(300, "Не более 300 символов")
        .required("Обязательное поле"),
      terms: Yup.bool()
        .oneOf([true], "Условия политики ОПД должны быть приняты")
        .required("Обязательное поле"),
      honeypot: Yup.bool().oneOf([false], "You shall not pass, bot!"),
    }),
    onSubmit: values => {
      Axios({
        method: "post",
        url: contactLink,
        data: {
          name: values.name,
          email: values.email,
          thema: values.thema,
          message: values.message,
          terms: values.terms,
        },
      })
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
      document.getElementById("contactform").style.display = "none"
      document.getElementById("successmessage").style.display = "inline"
      formik.resetForm()
    },
  })
  return (
    <>
      <div id="contactform">
        <h2 className="display-4 pt-2">Контактная форма</h2>
        <p className="uk-text-meta">
          Записаться на консультацию или задать вопрос через Форму обратной
          связи:
        </p>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="uk-text-meta"
        >
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2} className="text-md-right">
              Имя:
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Ваше имя без пробелов в начале и в конце"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <small className="text-danger">{formik.errors.name}</small>
              ) : null}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2} className="text-md-right">
              Email:
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="email"
                placeholder="Электронный адрес"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <small className="text-danger">{formik.errors.email}</small>
              ) : null}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalPassword">
            <Form.Label column sm={2} className="text-md-right">
              Тема:
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Тема сообщения без пробелов в начале и в конце"
                {...formik.getFieldProps("thema")}
              />
              {formik.touched.thema && formik.errors.thema ? (
                <small className="text-danger">{formik.errors.thema}</small>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label as="legend" column sm={2} className="text-md-right">
              Сообщение:
            </Form.Label>
            <Col sm={10}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  required
                  as="textarea"
                  rows="3"
                  {...formik.getFieldProps("message")}
                />
                {formik.touched.message && formik.errors.message ? (
                  <small className="text-danger">{formik.errors.message}</small>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalCheck">
            <Col sm={{ span: 10, offset: 2 }}>
              <Form.Check
                label="Разрешаю обработку персональных данных"
                type="checkbox"
                {...formik.getFieldProps("terms")}
              />
              {formik.touched.terms && formik.errors.terms ? (
                <small className="text-danger">{formik.errors.terms}</small>
              ) : null}
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            controlId="formHoneyPot"
            className="ohnohoneybot"
          >
            <Col sm={{ span: 10, offset: 2 }}>
              <Form.Check
                tabIndex="-10"
                label="Сладкий мед для ботов"
                type="checkbox"
                {...formik.getFieldProps("honeypot")}
              />
              {formik.touched.honeypot && formik.errors.honeypot ? (
                <small className="text-danger">{formik.errors.honeypot}</small>
              ) : null}
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col sm={{ span: 10, offset: 2 }}>
              <Button type="submit" variant="info" size="lg" block>
                Отправить
              </Button>
            </Col>
          </Form.Group>
        </Form>
        <p className="uk-text-meta">
          Отправляя эту форму вы соглашаетесь с политикой обработки персональных
          данных веб-сайта и хранением представленной информации.
        </p>
      </div>
      <div className="pt-5">
        <div id="successmessage" style={{ display: "none" }}>
          <Alert variant="info">
            <Alert.Heading>Сообщение отправлено!</Alert.Heading>
            <p>
              Спасибо, Вам за Ваше сообщение!
              <br /> Я рассмотрю Ваше обращение и свяжусь с Вами при первой же
              возможности. А пока вы можете проверить раздел{" "}
              <Link to="/faq">Часто задаваемых вопросов</Link> или просмотреть
              последние сообщения в блоге или инстаграме. <br />
              Если ваш запрос срочный, воспользуйтесь указанным выше номером
              телефона, чтобы поговорить со мной. В противном случае я отвечу
              Вам по электронной почте как можно скорее...
              <br />
              Если сообщение носит рекламный характер или, попросту СПАМ, то не
              обессудьте, я его могу проигнорировать.
              <br />
              <br />
              До скорого, <br />
              <br />
              <b>Александр Степаненко</b>
            </p>
          </Alert>
        </div>
      </div>
      <Link to="/policy">Политика обработки персональных данных</Link>
    </>
  )
}
export default ContactForm
